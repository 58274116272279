












































































import { Vue, Provide, Component } from "vue-property-decorator";
import { Toast } from "vant";
import serve from "@/serve/home.ts";
interface Type {
  key: string;
}
@Component({})
export default class Home extends Vue {
  shopData: Array<object> = []
  QQdata = []
  showDia = false
  showDialog = false
  @Provide() searchType = "店铺";
  @Provide() img = require("@/assets/img/store.jpg");
  @Provide() goods = require("@/assets/img/goods.jpg");
  @Provide() loading = false; //是否处于加载状态
  @Provide() finished = false; //是否已加载完所有数据
  @Provide() isLoading = false; //是否处于下拉刷新状态
  @Provide() keyword = "";
  @Provide() page = 0;
  @Provide() size = 10;
  @Provide() show = false;
  @Provide() columns = [
    {
      key: "商品",
    },
    {
      key: "店铺",
    },
  ];
  created() {
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword.toString();
      // this.getShops(this.keyword);
    }
  }
  private onCancel(): void {
    this.show = false;
  }
   async collect(id: string){
    const res = await serve.collectShop(id)
    if(res.data.code === 0){
      Toast('收藏成功');
      this.getShops(this.keyword)
    }else {
      Toast(res.data.message);
    }
  }
  chooseQQ(val: object): void {
    this.QQdata = val['qq']
    this.showDia  = true
  }
  openQQ(val?: string): void{
    window.location.href=`mqqwpa://im/chat?chat_type=wpa&uin=${val}&version=1&src_type=web&web_src=oicqzone.com`
  }
  async cancelCollect(id: string) {
    const res = await serve.cancleCollect(id)
    if(res.data.code === 0){
      Toast('取消收藏成功');
      this.getShops(this.keyword)
    }else {
      Toast(res.data.message);
    }
  }
  async getShops(val: string) {
    // 获取推荐商家
    const res = await serve.getShops({keyword:val, page:this.page, size:this.size});
    if (res.data.code === 0) {
      this.shopData = this.shopData.concat(res.data.data.content);
      this.loading = false;
      this.isLoading = false;
      if (res.data.data.totalElements <= this.shopData.length) {
        this.finished = true;
      }else{
        this.finished = false;
      }
    } else {
      Toast(res.data.message);
    }
  }
  private onConfirm(val: Type): void {
    this.searchType = val.key;
    this.show = false;
  }

  private goTo(val: string): void {
    this.$router.push(val);
  }

  private search(): void {
    if (this.searchType == "商品") {
      this.$router.push(`/searchGoods?keyword=${this.keyword}`);
    } else if (this.searchType == "店铺") {
      this.size = 10
      this.page = 0
      this.getShops(this.keyword)
    }
  }

  private onLoad(): void {
    // 上拉加载
    this.loading = true;
    this.getShops(this.keyword);
    this.page ++
  }

  private onRefresh(): void {
    //下拉刷新
    this.shopData = [];
    this.isLoading = true;
    this.page = 0;
    this.size = 10;
    this.getShops(this.keyword);
  }
}
